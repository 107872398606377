.avatar-grid {
    justify-content: center;
}

.avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    margin-bottom: 10px;
    height: 150px !important;
    width: 150px !important;
}
