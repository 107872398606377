/* App.css */

@font-face {
  font-family: 'DisneyFont';
  src: url("./fonts/DansDisney-3nJ8.ttf") format('truetype');
}

.App {
  text-align: center;
}

.drawer-link {
  background-image: linear-gradient(
    to right,
    #cb9b51 0%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  color: transparent;
  -webkit-background-clip: text;
}


.banner {
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
}